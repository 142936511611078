import { render, staticRenderFns } from "./CadastroAluno.vue?vue&type=template&id=5360b867&scoped=true&"
import script from "@/controllers/CadastroController.ts?vue&type=script&lang=ts&"
export * from "@/controllers/CadastroController.ts?vue&type=script&lang=ts&"
import style0 from "./CadastroAluno.vue?vue&type=style&index=0&id=5360b867&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5360b867",
  null
  
)

export default component.exports