import { CodigoDescricaoDTO } from '@/dtos/CodigoDescricaoDTO';
import { cacheHttp } from '@/request/index';

export default {
  getCidades(uf: string) {
    const url = `endereco/${uf}`;
    return cacheHttp.get<CodigoDescricaoDTO[]>(url);
  },

  getBairros() {
    const url = `/endereco/bairros`;
    return cacheHttp.get<string[]>(url);
  },

  getBairrosComVinculoDeEscola() {
    const url = `/endereco/bairroscomescola`;
    return cacheHttp.get<string[]>(url);
  }
}